$(document).ready(function () {
    if (!window.matchMedia || (window.matchMedia("(min-width: 769px)").matches)) {
        $('[data-toggle="tooltip"]').tooltip({
            animation: true,
            delay: {"show": 100, "hide": 100},
            html: true,
            trigger: 'hover',
            placement: 'top',
            container: 'body',
        });
    }

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    //
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })

    $(document).on('click', '.jsonScroll', function (e) {
        e.preventDefault();
        window.console.log("jsonScroll=");
        var json = JSON.parse($(this).attr("data-json"));
        window.console.log("jsonScroll=");
        jsonScroll(json);
    });

});

function jsonScroll(json) {
    console.log($(json['data_value_ref']).offset().top)
    $('html, body').animate({
        scrollTop: $(json['data_value_ref']).offset().top
    }, 2000);
}
