$(function () {
    window.onscroll = function() {scrollIndicator()};
});

function scrollIndicator() {
    console.log('scrollIndicator');
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    if(document.getElementById("myBar")) {
        document.getElementById("myBar").style.width = scrolled + "%";
    }
    //document.getElementById("myBarMobile").style.width = scrolled + "%";
}

